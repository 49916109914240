import React from 'react';
// Import icons from a library like react-icons
import { SiJavascript, SiReact, SiNodedotjs, SiCss3, SiTypescript, SiHtml5, SiGit, SiSwagger, SiSwift } from 'react-icons/si';

const Skills = () => {
    // Skills data now includes an icon component for each skill
    const skillsList = [
      { name: 'TypeScript', Icon: SiTypescript },
      { name: 'JavaScript', Icon: SiJavascript },
      { name: 'React', Icon: SiReact },
      { name: 'Node.js', Icon: SiNodedotjs },
      { name: 'CSS', Icon: SiCss3 },
      { name: 'HTML5', Icon: SiHtml5 },
      { name: 'Git', Icon: SiGit },
      { name: 'REST APIs', Icon: SiSwagger }, // Example, change if needed
      { name: 'Swift', Icon: SiSwift }
    ];
  
    return (
      <section className="p-8 dark:bg-gray-800 dark:text-gray-200">
        <h2 className="text-2xl font-bold">Skills</h2>
        <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
          {skillsList.map(({ name, Icon }) => (
            <div key={name} className="flex flex-col items-center justify-center p-4 border border-gray-300 dark:border-gray-600 rounded hover:bg-gray-700 transition duration-300 hover:scale-105 transform hover:shadow-lg">
              <Icon className="text-3xl mb-2 hover:animate-spin"/>
              <span className="hover:text-yellow-300">{name}</span>
            </div>
          ))}
        </div>
      </section>
    );
};


export default Skills;
