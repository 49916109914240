const Navbar = () => {
  return (
      <nav className="bg-gray-800 p-4 text-white">
          <ul className="flex justify-around">
              <li><a href="#about">About</a></li>
              <li><a href="#projects">Projects</a></li>
              <li><a href="#skills">Skills</a></li>
          </ul>
      </nav>
  );
};

export default Navbar;
