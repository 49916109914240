import { SiJavascript, SiReact, SiTypescript, SiHtml5, SiSwagger, SiTailwindcss, SiGooglechrome, SiPython, SiOpenai, SiTensorflow, SiNumpy } from 'react-icons/si';


const ProjectCard = ({ title, imageUrl, githubUrl, description, technologies, status, isAwardWinning}) => {

  return (
    <div className="relative flex flex-col bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden h-full hover:scale-105 transform transition duration-300 hover:shadow-2xl">
      
      <img src={imageUrl} alt={title} className="w-full h-32 sm:h-48 object-cover" />
      {isAwardWinning && (
        <div className="absolute top-0 right-0 bg-yellow-500 text-black py-1 px-3 rounded-bl-lg">
          Hackathon Winner
        </div>
      )}
      <div className="flex flex-col justify-between flex-grow p-4">
        <div>
          <h3 className="text-lg font-bold">{title}</h3>
          <p className="text-gray-300 mt-2">{description}</p>
        </div>

        <div>
          <div className="flex flex-wrap mt-4">
            {technologies.map(({ name, Icon }) => (
              <div key={name} title={name} className="inline-flex items-center justify-center mr-2 mb-2">
                <Icon className="text-2xl" />
              </div>
            ))}
          </div>
          
          {(status !== "Private Product 🔒") ? (
            <a href={githubUrl} target="_blank" rel="noopener noreferrer" className="inline-block text-indigo-500 hover:text-indigo-400">
              {status}
            </a>
          ) : (
            <button className="inline-block text-indigo-500 hover:text-red-400" disabled>
              Private Product 🔒
            </button>
          )}
        </div>
      </div>
    </div>
  );
};





const Projects = () => {
  const projects = [
    {
      title: 'Listen AT',
      imageUrl: '/listenat.jpeg', 
      status:'View on Devpost 🔗',
      githubUrl: 'https://devpost.com/software/listen-at',
      description: "An AI that can turn American Sign Language into speech as well as text. Listen AT contains a neural network trained to understand ASL and will speak whatever is in view of its camera. This project won \"Best Use of AI in Education\" at Stony Brook University's 2024 SBUHacks competition.",
      technologies: [
        { name: 'Python', Icon: SiPython},
        { name: 'Tensorflow', Icon: SiTensorflow },
        { name: 'Numpy', Icon: SiNumpy },
      ],
      isAwardWinning: true,
    },
    {
      title: 'LungVision AI',
      imageUrl: '/lungvision.png', 
      status:'View on Devpost 🔗',
      githubUrl: 'https://devpost.com/software/lungvision-ai',
      description: "LungVision AI: Transforming pneumonia detection with AI. Enhances diagnoses, trains doctors, and promotes better healthcare outcomes. Fast, accurate, and reliable.",
      technologies: [
        { name: 'Python', Icon: SiPython},
        { name: 'JavaScript', Icon: SiJavascript },
        { name: 'Tensorflow', Icon: SiTensorflow },
      ],
      isAwardWinning: false,
    },
    {
      title: 'SBU Meal Assistant',
      imageUrl: '/sbumeal.png', 
      status:'View on Devpost 🔗',
      githubUrl: 'https://devpost.com/software/sbu-meal-assistant',
      description: "SBU Meal Assistant is a web application that shares dining information across the Stony Brook campus. Using artificial intelligence, the SBU Meal Assistant can chat with users to filter and discuss menu items to fit a student's preference. The student may relay to the Food Assistant any restrictions or allergies they may have, and it will recommend dishes based on those elements. Even if you have no restrictions and are just feeling pizza today, the assistant will let you know where and what types are available! This project won \"Best Beginner Hack\" at Stony Brook University's 2024 Hopper Hacks competition.",
      technologies: [
        { name: 'TypeScript', Icon: SiTypescript },
        { name: 'OpenAI', Icon: SiOpenai },
        { name: 'Python', Icon: SiPython },
      ],
      isAwardWinning: true,
    },
    {
      title: 'Sneaker Aggregator',
      imageUrl: '/pork.png', 
      status:'Private Product 🔒',
      githubUrl: '',
      description: "Sneaker Aggregator is a dynamic web application tailored for business clients in the sneaker industry, enabling informed inventory purchasing decisions through real-time price fetching from multiple marketplaces. Built using React, TypeScript, and a suite of backend technologies, it streamlines market analysis for efficient business operations.      ",
      technologies: [
        { name: 'TypeScript', Icon: SiTypescript },
        { name: 'React', Icon: SiReact },
        { name: 'TailwindCSS', Icon: SiTailwindcss },
      ],
      isAwardWinning: false,
    },
    {
      title: 'SCCC Chrome Extension',
      imageUrl: '/sccc.png', 
      githubUrl: 'https://github.com/PatFDev/RateMyProfessorSCCC', 
      status: 'View on GitHub 🔗',
      description: "Integrative Chrome extension that imports Rate My Professor ratings into Suffolk Community College's class enrollment interface to aid students in making informed decisions based on instructor evaluations.",
      technologies: [
        { name: 'Javascript', Icon: SiJavascript },
        { name: 'Chrome Extension', Icon: SiGooglechrome },
        { name: 'APIs', Icon: SiSwagger },
      ],
      isAwardWinning: false,
    },
    {
      title: 'Stadium Goods Chrome Extension',
      imageUrl: '/SG.png', 
      githubUrl: 'https://github.com/PatFDev/StadiumGoodsExtension', 
      status: 'View on GitHub 🔗',
      description: 'Chrome extension for revealing last sale dates and prices on StadiumGoods.com, streamlining market analysis and purchasing decisions.', 
      technologies: [
        { name: 'Javascript', Icon: SiJavascript },
        { name: 'HTML5', Icon: SiHtml5 },
        { name: 'APIs', Icon: SiSwagger },
      ],
      isAwardWinning: false,
    }
  ];

  return (
    <section className="p-8 bg-gray-900">
      <h2 className="text-2xl font-bold text-white">Projects</h2>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </section>
  );
};

export default Projects;
