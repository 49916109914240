// App.js

import React from 'react';
import './App.css'; // Make sure to import the Tailwind CSS file

// Importing components
import Navbar from './components/Navbar.js';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';


function App() {
  return (
    <div className="dark">
      <Navbar />
      <main>
        <div id="about">
          <About />
        </div>
        <div id="projects">
          <Projects />
        </div>
        <div id="skills">
          <Skills />
        </div>
      </main>
    </div>
  );
}

export default App;
