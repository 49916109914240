import React from 'react';
import TwitterIcon from './SVGs/TwitterIcon';
import GitHubIcon from './SVGs/GitHubIcon';
const socialLinks = {
  twitter: "https://twitter.com/",
  github: "https://github.com/PatFDev"
};

const About = () => {
  return (
    // Outer section with increased top padding and reduced minimum height
    <section className="flex justify-center items-center pt-8 pb-4 bg-gray-900">
      {/* Card container with shadow, rounded corners, and reduced padding */}
      <div className="w-full max-w-lg bg-gray-800 rounded-lg shadow-lg p-4 text-center dark:text-gray-200">
        <img 
          src="/portfolio.png" 
          alt="Profile" 
          className="w-32 h-32 rounded-full mx-auto" 
        />
        <h3 className="text-xl font-semibold mt-3">Patrick Farrell</h3>
        <p className="mt-1">
        Future Software Engineer and current student at Suffolk County Community College
        </p>
        <div className="flex justify-center mt-3 space-x-3">
          <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter" className = 'icon'>
            <TwitterIcon />
          </a>
          <a href={socialLinks.github} target="_blank" rel="noopener noreferrer" aria-label="GitHub" className = 'icon'>
            <GitHubIcon />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
